// export const FP_BASE_URL = 'http://166.111.80.235:2333';
// export const VIS_BASE_URL = 'http://166.111.80.235:2333';
// export const FP_BASE_URL = process.env.REACT_APP_BACKEND_BASE;
// export const VIS_BASE_URL = process.env.REACT_APP_BACKEND_BASE;
//
// export const BASE_URL = process.env.REACT_APP_BACKEND_BASE;
// export const BC_BASE_URL = process.env.REACT_APP_BACKEND_BASE;
var baseURL,bcBaseURL,fpBaseURL,dwgBaseURL;if(process.env.REACT_APP_BACKEND_TYPE==='dev'){baseURL='http://166.111.80.235:5000';bcBaseURL='http://166.111.80.235:9999/BCServer';fpBaseURL='http://166.111.80.235:2333';dwgBaseURL='http://166.111.80.235:10111/dwg-split';}else if(process.env.REACT_APP_BACKEND_TYPE==='public'){baseURL='https://api.tuzhi.ai';bcBaseURL='https://api.tuzhi.ai/bc';fpBaseURL='https://api.tuzhi.ai/rec';dwgBaseURL='http://166.111.80.216:10111/dwg-split';}else if(process.env.REACT_APP_BACKEND_TYPE==='local'||!process.env.REACT_APP_BACKEND_TYPE){baseURL='http://127.0.0.1:5000';bcBaseURL='http://166.111.80.235:9999/BCServer';// bcBaseURL = 'http://127.0.0.1:9999/BCServer';
fpBaseURL='http://166.111.80.235:2333';// fpBaseURL = 'http://127.0.0.1:2333';
// dwgBaseURL = 'http://127.0.0.1:10111/dwg-split';
dwgBaseURL='http://166.111.80.235:10111/dwg-split';// 本地开发测试图智服务器时使用下面配置
// baseURL = 'https://api.tuzhi.ai';
// bcBaseURL = 'https://api.tuzhi.ai/bc';
// fpBaseURL = 'https://api.tuzhi.ai/rec';
}export var BASE_URL=baseURL;export var BC_BASE_URL=bcBaseURL;export var FP_BASE_URL=fpBaseURL;export var DWG_BASE_URL=dwgBaseURL;